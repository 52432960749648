<template>
    <div class="watermark-container">
      <slot></slot>
      <div class="watermark" :style="watermarkStyle"></div>
    </div>
  </template>
  
  <script>
  /*使用方式放在id：print里面进行包裹*/ 
  export default {
    name: 'Watermark',
    props: {
      text: {
        type: String,//文本
        default: '优加惠品'
      },
      fontSize: {
        type: Number,//大小
        default: 20
      },
      color: {
        type: String,//颜色
        default: 'rgba(0, 0, 0, 0.1)'
      },
      rotate: {
        type: Number,//偏移度
        default: -10
      }
    },
    computed: {
      watermarkStyle() {
        return {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none', // 防止水印干扰点击事件
          backgroundImage: `url("${this.generateWatermarkImage()}")`,
          backgroundRepeat: 'repeat',
          zIndex: 9999
        };
      }
    },
    methods: {
      generateWatermarkImage() {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        // 设置画布大小
        canvas.width = 800;
        canvas.height = 600;
  
        // 设置字体样式
        ctx.font = `500 ${this.fontSize}px Arial`;
        // ctx.fillStyle = this.color;
        // ctx.textAlign = 'center';
        // ctx.textBaseline = 'middle';
        
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.globalAlpha = 0.2;
        // ctx.translate(175, 125);
        ctx.rotate(-Math.PI / -10);
        ctx.translate(canvas.width / 3, canvas.height / 3);
        // ctx.rotate((this.rotate * Math.PI) / 180);
        ctx.fillText(this.text, 0, 0);
  
        // 返回 base64 图片
        return canvas.toDataURL();
      }
    }
  };
  </script>
  
  <style scoped>
  .watermark-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .watermark {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* 防止水印干扰点击事件 */
  }
  
  /* 打印样式 */
  @media print {
    .watermark {
      -webkit-print-color-adjust: exact; /* Chrome/Safari */
      print-color-adjust: exact; /* 标准属性 */
    }
    .watermark {
        display: block;
        position: fixed; /* 打印时使用固定定位 */
        width: 100%;
        height: 100vh;
        z-index: 9999;
        pointer-events: none;
        top: 0;
        left: 0;
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
    }

  }
  </style>