<template>
  <div>
    <a-card style="background-color: #fff;">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="产品类型" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.productType" style="width: 90%" placeholder="请选择产品类型"
                    :default-value="null" option-label-prop="label">
                    <a-select-option :value="item.value" :label="item.label" v-for=" (item, index) in productTypeList"
                      :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="销售单状态" :labelCol="{ span: 6 }" :wrapperCol="{ span: 17, offset: 1 }">
                  <a-select allowClear v-model="queryData.status" style="width: 90%" placeholder="请选择销售单状态"
                    :default-value="null" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in [{id:1,name:'进行中'},{id:2,name:'已完成'},{id:3,name:'已取消'}]"
                      :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="客户名称" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select show-search :value="searchVal" style="width: 90%"  placeholder="请输入客户名称" :default-active-first-option="false"
                    :show-arrow="false" :filter-option="false" :not-found-content="null" @search="fetchUser"
                    @change="handleSearchChange">
                    <a-select-option v-for="d in customerData" :key="d.name" :label="d.name" :value="d.id">
                      {{ d.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="当前客户经理" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select v-model="queryData.staffIdList" mode="multiple" style="width: 90%"  placeholder="请选择客户经理"
                    :default-value="null" :filter-option="filterOption" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in userData" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> 


              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="产品售价" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-row>
                    <a-col :span="10">
                      <a-input v-model="queryData.amountMin" placeholder="最小价格" />
                    </a-col>
                    <a-col :span="2" style="text-align:center">
                      -
                    </a-col>
                    <a-col :span="10">
                      <a-input v-model="queryData.amountMax" placeholder="最大价格" />
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="创建时间" :labelCol="{ span: 6 }" :wrapperCol="{ span: 15, offset: 1 }">
                  <a-range-picker :value="dateValue" :ranges="{
        '今天': [moment(), moment()],
        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '当月': [moment().startOf('month'), moment().endOf('month')],
        '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }" @change="dateChange" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="销售单号" :labelCol="{ span: 7 }" :wrapperCol="{ span: 15, offset: 1 }">
                  <a-input v-model="queryData.code" placeholder="请输入销售单号" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;" @click="search">查询
                </a-button>

              </a-col>
            </a-row>

            <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至
              {{ queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项
            </div>
          </div>
        </a-form>
      </div>
      <div>
        <a-table class="table-box" :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false" bordered size="small"
          :scroll="{ x: 1500 }">
          <template slot="createTime" slot-scope="text">
            <span class="table-text">{{ formatDate(text) }}</span>
          </template>
          <template slot="updateTime" slot-scope="text">
            <span class="table-text">{{ formatDate(text) }}</span>
          </template>
        
          <template slot="needType" slot-scope="text">
            <span class="table-text" v-if="!text">线下活动</span>
          </template>
          <template slot="updateByName" slot-scope="text">
            <span class="table-text">{{text}}</span>
          </template>
          <template slot="customerDesc" slot-scope="text">
            <span class="table-text">{{text}}</span>
          </template>
          <template slot="code" slot-scope="text">
            <span class="table-text">{{text}}</span>
          </template>
          <template slot="typeDesc" slot-scope="text">
            <span class="table-text">{{text}}</span>
          </template>
          <template slot="customerDesc" slot-scope="text">
            <span class="table-text">{{text}}</span>
          </template>
          <template slot="projectTypeDesc" slot-scope="text">
            <span class="table-text" v-if="!text">-</span>
            <span class="table-text" v-else>{{ text }}</span>
          </template>
          <template slot="invoiceAmount" slot-scope="text">
              <span class="table-text">{{ text ? text.toFixed(2):'0.00' }}</span>
          </template>
          <template slot="invoiceStatusDesc" slot-scope="text">
              <span class="table-text">{{text}}</span>
          </template>
          <template slot="amount" slot-scope="text">
              <span class="table-text">{{ text ? text.toFixed(2):'0.00' }}</span>
          </template>
          <template slot="plfPrice" slot-scope="text">
              <span class="table-text">{{ text ? text.toFixed(2):'0.00' }}</span>
          </template>
          
          <template v-if="record.statusDesc=='已完成'" slot="action" slot-scope="text,record">
            <div class="action-box">
              <a @click="Printing(record.id,'1',record.invoiceStatus)">打印销售单</a>
            </div>
          </template>
        </a-table>
        <div class="page-box">
          <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
            :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
            show-size-changer />
        </div>
      </div>
    </a-card>
    <div v-if="printVisit">
        <a-modal :visible="printVisit" :footer="null" width="80%" :closable="false">
        <div class="print-main" id="print">
          <Watermark :text="printData.saleStaffName+formatDate(printData.createTime)" :fontSize="25" color="rgba(255, 0, 0, 0.3)" :rotate="-45">

          <h2 class="print-title">线下活动销售单</h2>
          <div style="position:absolute;right:10px;top:10px;font-size:16px;text-align:center">
            <p style="font-size:18px">
              <span>销售订单号：</span>
              <span style="color:#333;">{{ printData.code }}</span>
            </p>
            <p>
              <span>创建时间：</span>
              <span>{{ formatDate(printData.createTime) }}</span>
            </p>
          </div>
          <ul class="print-top">
            <li>
              <span>客户名称：</span>
              <span>{{ printData.customerName }}</span>
            </li>
            <li>
              <span>销售公司：</span>
              <span>{{ printData.sellerName }}</span>
            </li>
            <li v-if="printData.returnCustomerName">
              <span>回款客户公司：</span>
              <span>{{ printData.returnCustomerName }}</span>
            </li>
          </ul>
          <ul class="print-top print-top2">
            <li>
              <span>开票金额：</span>
              <span>{{Number(printData.invoiceAmount).toFixed(2)}}</span>
              <div v-if="printData.invoiceAmount!=printData.executionBillVo?.invoiceAmount" class="underlin">{{ Number(printData.executionBillVo.invoiceAmount).toFixed(2)}}</div>
            </li>
            <li>
              <span>M+:</span>
              <span>{{ Number(printData.businessExpenses).toFixed(2)}}</span>
              <div v-if="printData.invoiceAmount!=printData.executionBillVo?.invoiceAmount" class="underlin"  style="text-indent: 32px;">{{ Number(printData.executionBillVo.businessExpenses).toFixed(2)}}</div>
            </li>
            <li>
              <span>产品售价：</span>
              <span>{{  Number(printData.amount).toFixed(2)}}</span>
              <div v-if="printData.amount!=printData.executionBillVo?.amount" class="underlin">{{ Number(printData.executionBillVo.amount).toFixed(2)}}</div>
            </li>
            <li>
              <span>优加底价：</span>
              <span>{{  Number(printData.plfPrice).toFixed(2)}}</span>
              <div v-if="printData.plfPrice!=printData.executionBillVo?.plfPrice" class="underlin">{{ Number(printData.executionBillVo.plfPrice).toFixed(2)}}</div>
            </li>
            <li>
              <span>利润：</span>
              <span>{{  Number(printData.profit).toFixed(2)}}</span>
              <div v-if="printData.profit!=printData.executionBillVo?.profit" class="underlin" style="text-indent: 55px;">{{ Number(printData.executionBillVo.profit).toFixed(2)}}</div>
            </li>
            <li>
              <span>项目等级：</span>
              <span>{{ printData.projectTypeName }}</span>
            </li>
            <li>
              <span>是否需要开票：</span>
              <span>{{ printData.invoiceTypeName }}</span>
            </li>
            <li>
              <span>回款方式：</span>
              <span>{{ printData.repayTypeName }}</span>
            </li>
            <li v-if="printData.sfaSaleContractVo">
              <span>合同：</span>
              <span>{{ printData.sfaSaleContractVo.typeName}}</span>
            </li>
            <li v-if="printData.sfaSaleContractVo && printData.sfaSaleContractVo.typeName =='需要签订'">
              <span>合同份数：</span>
              <span>{{ printData.sfaSaleContractVo.quantity }}</span>
            </li>
            <li v-if="printData.sfaSaleContractVo&& printData.sfaSaleContractVo.typeName =='需要签订'">
              <span>回合同份数：</span>
              <span>{{ printData.sfaSaleContractVo.revertQuantity }}</span>
            </li>
            <li v-if="printData.sfaSaleContractVo&& printData.sfaSaleContractVo.typeName !='需要签订'">
              <span>备注：</span>
              <span>{{ printData.sfaSaleContractVo.notes }}</span>
            </li>
            <li>
              <span>进项税费：</span>
              <span>{{ printData?.inputTaxAmount || 0}}</span>
            </li>
            <li v-if="printData.expectReturnTime" style="width: 430px;">
              <span>预计回款时间：</span><span>{{ printData.expectReturnTime }}
              <span v-if="printData.repayStatus =='1'">（全额回款）</span>
              <span v-else >（待回款）</span>
              <!-- <span v-if="printData.repayStatus =='3'">（未回款）</span> -->
              </span>
            </li>
          </ul>
          <!-- 执行单信息 -->
          <a-card :title="printData.executionBillVo?.typeName" :bordered="false"></a-card>
          <div style="margin-top: 10px;" class="print-box">
            <a-card title="执行单" :bordered="false"></a-card>
            <div class="print-text">
            <div class="print-titles">基本信息</div>
            <a-descriptions title="">
              <a-descriptions-item label="开票金额">
                {{ Number(printData.executionBillVo?.invoiceAmount).toFixed(2)}}
              </a-descriptions-item>
              <a-descriptions-item label="M+">
                {{ Number(printData.executionBillVo?.businessExpenses).toFixed(2)}}
              </a-descriptions-item>
              <a-descriptions-item label="产品售价">
                {{ Number(printData.executionBillVo?.amount).toFixed(2)}}
              </a-descriptions-item>
              <a-descriptions-item label="优加低价">
                {{ Number(printData.executionBillVo?.plfPrice).toFixed(2)}}
              </a-descriptions-item>
              <a-descriptions-item label="利润">
                {{ Number(printData.executionBillVo?.profit).toFixed(2)}}
              </a-descriptions-item>
            </a-descriptions>
           
          </div>
          <div class="print-table">
            <a-card
              title="活动信息" :bordered="false">
              <div v-if="printData.executionBillVo?.offilneActivityDetailsVoList.length > 0">
                  <div style="margin-top: 10px;"  v-for="(item,index) in printData.executionBillVo.offilneActivityDetailsVoList" :key="index">
                  <a-table :columns="printColumns" bordered :dataSource="[item]" :pagination="false" size="middle">
                    <template slot="startTime" slot-scope="record,text">
                      <div>
                        <span>{{ formatDate(text.startTime) }}-{{ formatDate(text.endTime) }}</span>
                      </div>
                    </template>
                    <template slot-scope="record">
                      <span>{{ record }}</span>
                    </template>
                  </a-table>
                  <a-divider />
                </div>
              </div>
              <div v-else>
                <p style="text-align: center;">无相关内容</p>
              </div>
            </a-card>
          </div>
          <div class="print-table">
            <a-card 
              title="物料信息" :bordered="false">
              <div v-if="printData.executionBillVo?.materielList?.length > 0">
                <div style="margin-top: 10px;" v-for="(item,index) in printData.executionBillVo.materielList" :key="index">
                  <a-table :columns="materialColumns" bordered :dataSource="[item]" :pagination="false" size="middle">
                    <template  slot-scope="record">
                      <span>{{ record }}</span>
                    </template>
                  </a-table>
                  <a-divider />
                </div>
              </div>
              <div v-else>
                <p style="text-align: center;">无相关内容</p>
              </div>
            </a-card>
          </div>
          <div class="print-table">
            <a-card
              title="奖品信息" :bordered="false">
              <div v-if="printData.executionBillVo?.ordersKaList?.length > 0">
                <div style="margin-top: 10px;" v-for="(item,index) in printData.executionBillVo.ordersKaList" :key="index">
                  <a-table :columns="awardColumns" bordered :dataSource="[item]" :pagination="false" size="middle">
                    <template  slot-scope="record">
                      <span>{{ record }}</span>
                    </template>
                  </a-table>
                  <a-divider />
                </div>
              </div>
              <div v-else>
                <p style="text-align: center;">无相关内容</p>
              </div>
            </a-card>
          </div>
          <div class="print-table">
            <a-card
              title="人员信息" :bordered="false">
              <div v-if="printData.executionBillVo?.stafflList?.length > 0">
                <div style="margin-top: 10px;" v-for="(item,index) in printData.executionBillVo.stafflList" :key="index">
                  <a-table :columns="personColumns" bordered :dataSource="[item]" :pagination="false" size="middle">
                    <template  slot-scope="record">
                      <span>{{ record }}</span>
                    </template>
                  </a-table>
                  <a-divider />
                </div>
              </div>
              <div v-else>
                <p style="text-align: center;">无相关内容</p>
              </div>
            </a-card>
          </div>
          </div>
          <!-- 变更信息 -->
          <div class="print-box">
            <div v-if="printData.alterationExecutionBillVo">
                <a-card title="变更信息" :bordered="false"></a-card>
              <div class="print-table">
                <a-card
                  v-if="printData.alterationExecutionBillVo?.offilneActivityDetailsVoList"
                  title="活动信息" :bordered="false">
                  <div style="margin-top: 10px;"  v-for="(item,index) in printData.alterationExecutionBillVo.offilneActivityDetailsVoList" :key="index">
                    <a-table :columns="printColumns" bordered :dataSource="[item]" :pagination="false" size="middle">
                      <template slot="startTime" slot-scope="record,text">
                        <div>
                          <span>{{ formatDate(text.startTime) }}-{{ formatDate(text.endTime) }}</span>
                        </div>
                      </template>
                      <template slot-scope="record">
                        <span>{{ record }}</span>
                      </template>
                    </a-table>
                    <a-divider />
                  </div>

                </a-card>
              </div>
              <div class="print-table">
                <a-card v-if="printData.alterationExecutionBillVo.materielList"
                  title="物料信息" :bordered="false">
                  <div style="margin-top: 10px;" v-for="(item,index) in printData.alterationExecutionBillVo.materielList" :key="index">
                    <a-table :columns="materialColumns" bordered :dataSource="[item]" :pagination="false" size="middle">
                      <template  slot-scope="record">
                        <span>{{ record }}</span>
                      </template>
                    </a-table>
                    <a-divider />
                  </div>
                </a-card>
              </div>
              <div class="print-table">
                <a-card v-if="printData.alterationExecutionBillVo.ordersKaList"
                  title="奖品信息" :bordered="false">
                  <div style="margin-top: 10px;" v-for="(item,index) in printData.alterationExecutionBillVo.ordersKaList" :key="index">
                    <a-table :columns="awardColumns" bordered :dataSource="[item]" :pagination="false" size="middle">
                      <template  slot-scope="record">
                        <span>{{ record }}</span>
                      </template>
                    </a-table>
                    <a-divider />
                  </div>
                </a-card>
              </div>
              <div class="print-table">
                <a-card v-if="printData.alterationExecutionBillVo.stafflList"
                  title="人员信息" :bordered="false">
                  <div style="margin-top: 10px;" v-for="(item,index) in printData.alterationExecutionBillVo.stafflList" :key="index">
                    <a-table :columns="personColumns" bordered :dataSource="[item]" :pagination="false" size="middle">
                      <template  slot-scope="record">
                        <span>{{ record }}</span>
                      </template>
                    </a-table>
                    <a-divider />
                  </div>
                </a-card>
              </div>
            </div>
            <div v-else>
              <p style="text-align: center;">无变更内容</p>
            </div>
          </div>
          <!-- 发票信息 -->
          <div class="print-box">
            <a-card v-if="printData.invoicingInfoVoList"
              title="发票信息" :bordered="false">
              <div v-for="(item,index) in printData.invoicingInfoVoList" :key="index">
              <a-descriptions class="print-text" title="">
                <a-descriptions-item label="发票抬头">
                  {{item.invoiceVo.invoiceHeading|| '-'}}
                </a-descriptions-item>
                <a-descriptions-item label="社会统一信用代码">
                  {{item.invoiceVo.dutyParagraph|| '-'}}
                </a-descriptions-item>
                <a-descriptions-item label="开户行">
                  {{item.invoiceVo.bank|| '-'}}
                </a-descriptions-item>
                <a-descriptions-item label="发票类型名称">
                  {{item.invoiceVo.invoiceTypeName|| '-'}}
                </a-descriptions-item>
                <a-descriptions-item label="账号">
                  {{item.invoiceVo.bankAccount|| '-'}}
                </a-descriptions-item>
                <a-descriptions-item label="地址">
                  {{item.invoiceVo.address|| '-'}}
                </a-descriptions-item>
                <a-descriptions-item label="电话">
                  {{item.invoiceVo.phone|| '-'}}
                </a-descriptions-item>
              </a-descriptions>
              <div class="print-table" v-if="item.invoicingDetailsVo">
                <a-descriptions class="print-text" title="">
                    <a-descriptions-item label="开票金额">
                      {{ Number(item.invoicingDetailsVo.invoiceAmountAlready).toFixed(2)|| '-'}}
                    </a-descriptions-item>
                    <a-descriptions-item label="税额">
                      {{ Number(item.invoicingDetailsVo.invoiceTaxAmount).toFixed(2)|| '-'}}
                    </a-descriptions-item>
                  </a-descriptions>
                  <div style="font-size: 18px;font-weight: 500; color:rgba(0, 0, 0, 0.85); ">开票明细</div>
                  <div style="margin-top:10px" v-for="(list,index) in item.invoicingDetailsVo.invoicingContentVoList" :key="index">
                    <a-table :columns="invoiceColumns" bordered :dataSource="[list]" :pagination="false" size="middle">
                      <template  slot-scope="record">
                        <span>{{ record }}</span>
                      </template>
                    </a-table>
                  </div>
              </div>
              <a-divider />
              </div>
            </a-card>
          </div>
          <div class="print-btm">
            <p>有效业绩：</p>
            <p>提成金额：</p>
            <p>大单奖励：</p>
            <p>是否为合并项目：</p>
          </div>
          <div class="print-btm">
            <p>客户经理：</p>
            <p>人事负责人：</p>
            <p>销售总监：</p>
            <p>销售VP：</p>
            <p>销售部负责人：</p>
          </div>
      </Watermark>
        </div>
        <div class="print-btn no-print">
          <a-button type="primary" style="margin-right: 10px;" v-print="printObj" >打印预览</a-button>
          <!-- <span style="margin-right: 10px;color:#e6a23c;" v-else>进项税费为0，无法打印线下活动销售单</span> -->
          <a-button @click="printVisit = false">关闭</a-button>
        </div>
        <!-- <div style="width: 170px; margin:0 auto;" v-else>
          <a-button type="primary" style="margin-right: 10px;" @click="printMoel">打印预览</a-button>
          <a-button @click="printVisit = false">关闭</a-button>
        </div> -->
      </a-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
// import { orderPrintMixin } from '@/mixin/orderPrint'
import { publicMixin } from '@/mixin/public'
import debounce from 'lodash/debounce';
import Watermark from '@/components/watermark/index.vue';

const columns = [
  
  {
    title: '客户名称',
    dataIndex: 'customerDesc',
    scopedSlots: { customRender: 'customerDesc' },
    align: 'center',
  },
  {
    title: '销售单客户经理',
    dataIndex: 'salespersonName',
    align: 'center',
    width:140
  },
  {
    title: '当前客户经理',
    dataIndex: 'staffDesc',
    align: 'center',
    width:120
  },
  {
    title: '项目等级',
    dataIndex: 'projectTypeDesc',
    scopedSlots: { customRender: 'projectTypeDesc' },
    align: 'center',
  },
  {
    title: '开票金额',
    dataIndex: 'invoiceAmount',
    scopedSlots: { customRender: 'invoiceAmount' },
    align: 'center',
  },
  {
    title: '开票状态',
    dataIndex: 'invoiceStatusDesc',
    scopedSlots: { customRender: 'invoiceStatusDesc' },
    align: 'center',
  },
  {
    title: '产品售价',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' },
    align: 'center'
  },
  {
    title: '销售单状态',
    dataIndex: 'statusDesc',
    align: 'center',
    width:120
  },
  {
    title: '需求类型',
    dataIndex: 'needType',
    scopedSlots: { customRender: 'needType' },
    align: 'center'
  },
  {
    title: '产品类型',
    dataIndex: 'typeDesc',
    scopedSlots: { customRender: 'typeDesc' },
    align: 'center'
  },
  {
    title: '最后更新时间',
    dataIndex: 'updateTime',
    scopedSlots: { customRender: 'updateTime' },
    align: 'center',
    width:150
  },
  {
    title: '操作人',
    dataIndex: 'updateByName',
    scopedSlots: { customRender: 'updateByName' },
    align: 'center'
  },
  
  {
    title: '销售单号',
    dataIndex: 'code',
    scopedSlots: { customRender: 'code' },
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    align: 'center'
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    fixed: 'right',
    width: 160
  }
]

export default {
  name: 'offlineActiveList',
  components: { Watermark },
  mixin: [publicMixin],
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 900);
    return {
      searchVal:[],
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      invoiceColumns:[
        {
          title: '项目名称',
          dataIndex: 'invoiceContentName',
          scopedSlots: { customRender: 'invoiceContentName' },
          align: 'center',
        },
        {
          title: '规格',
          dataIndex: 'model',
          scopedSlots: { customRender: 'model' },
          align: 'center',
        },
        {
          title: '单位',
          dataIndex: 'unit',
          scopedSlots: { customRender: 'unit' },
          align: 'center',
        },
        {
          title: '数量',
          dataIndex: 'number',
          scopedSlots: { customRender: 'number' },
          align: 'center',
        },
        {
          title: '单价',
          dataIndex: 'unitPrice',
          scopedSlots: { customRender: 'unitPrice' },
          align: 'center',
        },
        {
          title: '金额',
          dataIndex: 'money',
          scopedSlots: { customRender: 'money' },
          align: 'center',
        },
        {
          title: '税费/征收率',
          dataIndex: 'taxRate',
          scopedSlots: { customRender: 'taxRate' },
          align: 'center',
        },
        {
          title: '税额',
          dataIndex: 'taxAmount',
          scopedSlots: { customRender: 'taxAmount' },
          align: 'center',
        },
      ],
      personColumns:[
        {
          title: '人员类型',
          dataIndex: 'typeDesc',
          scopedSlots: { customRender: 'typeDesc' },
          align: 'center',
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          scopedSlots: { customRender: 'quantity' },
          align: 'center',
        },
      ],
      awardColumns:[
        {
          title: '订单类型',
          dataIndex: 'typeDesc',
          scopedSlots: { customRender: 'typeDesc' },
          align: 'center',
        },
        {
          title: '订单号',
          dataIndex: 'code',
          scopedSlots: { customRender: 'code' },
          align: 'center',
        },
        {
          title: '商品总数量',
          dataIndex: 'quantity',
          scopedSlots: { customRender: 'name' },
          align: 'center',
        },
      ],
      materialColumns:[
        {
          title: '物料名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          align: 'center',
        },
        {
          title: '数量',
          dataIndex: 'buyCount',
          scopedSlots: { customRender: 'buyCount' },
          align: 'center',
        },
      ],
      printColumns: [
        {
          title: '活动时间',
          dataIndex: 'startTime',
          scopedSlots: { customRender: 'startTime' },
          align: 'center',
          width: 110
        },
        {
          title: '活动人数',
          dataIndex: 'number',
          scopedSlots: { customRender: 'number' },
          align: 'center',
          width: 110
        },
        {
          title: '活动地点',
          dataIndex: 'address',
          align: 'center',
          scopedSlots: { customRender: 'address' },
          width: 110
        },
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],

      // 筛选
      queryData: {
        page: 0,
        size: 10,
      },
      // 打印信息
      printVisit: false,//打印弹窗
        printData: {},
        printType: 0,
        printObj: {
          id: "print", //要打印的id名 无#号
          extraCss: "", // 打印可引入外部的一个 css 文件
          popTitle: "&nbsp;", //打印的页眉标题，默认浏览器标题 空字符串时显示undefined 使用html语言
          extraHead: "", //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
          preview: "", // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
          previewTitle: "", // 打印预览的标题（开启预览模式后出现）,
          previewPrintBtnLabel: "", // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
          zIndex: "", // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）
          previewBeforeOpenCallback() { }, //预览窗口打开之前的callback（开启预览模式调用）
          previewOpenCallback() { }, // 预览窗口打开之后的callback（开启预览模式调用）
          beforeOpenCallback() { }, // 开启打印前的回调事件
          openCallback() { }, // 调用打印之后的回调事件
          closeCallback() { }, //关闭打印的回调事件（无法确定点击的是确认还是取消）
          url: "",
          standard: "",
      },

      tableLoading: false,
      downLoading: false,
      tabCurrent: 1,
      downCurrent: 1,
      downTotal: 0,
      tabTotal: 0,
      currentPage: 1,
      total: 0,
      brandList: [],
      pdtCatList: [],
      dateValue: "",
      totalText: null,
      needTypeList: [
        {
          label: '线下活动',
          value: 5
        }
      ],
      // 活动类型
      productTypeList: [
        {
          label: '运动会',
          value: 1
        },
        {
          label: '手作',
          value: 2
        },
        {
          label: '游园会',
          value: 3
        },
        {
          label: '外出团建',
          value: 4
        },
        {
          label: '年会',
          value: 5
        },
        {
          label: '培训',
          value: 6
        },
        {
          label: '其他',
          value: 0
        }
      ],
    }
  },
  mounted() {    
    // 列表
    this.getData();
    // 销售
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post',{}).then(res => {
      this.userData = res.data.data
    })
  },
  created(){
    console.log(this.printVisit,'1111')
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    printMoel(){
      if(this.printData.inputTaxAmount==0){
        this.$message.warning('进项税费为空，无法打印线下活动销售单')
        return
      }
       
    },
    formatDateMins(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      // const hours = date.getHours();
      // const minutes = date.getMinutes();
      // const seconds = date.getSeconds();
      const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      return result;
    },
    getMenuName(menu) {
      let name = "";
      switch (menu) {
        case 1:
          name = "商城"
          break;
        case 2:
          name = "电影"
          break;
        case 3:
          name = "蛋糕"
          break;
        case 4:
          name = "礼包兑换"
          break;
        case 5:
          name = "生活"
          break;
        case 6:
          name = "储值卡购买礼包商品"
          break;
        case 7:
          name = "演出"
          break;
        case 8:
          name = "线下门店"
          break;
        case 9:
          name = "图书"
          break;
        case 10:
          name = "景点门票"
          break;
        case 11:
          name = "点餐"
          break;
      }
      return name;
    },
    fetchUser(value) {
      if (!value) {
        return false;
      }
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerData = [];
      this.fetching = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/listByCustomerName', 'post', { page: 0, size: 50, name: value }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerData = res.data.data.data;
        this.fetching = false;
      }).catch(error => {
        console.error('Error fetching user:', error);
      });
    },
    handleSearchChange(searchVal) {
      this.queryData.customerId = []
      this.queryData.customerId = searchVal;
      Object.assign(this, {
        searchVal,
        fetching: false,
      });
    },
    search() {
      this.queryData.page = 0;
      this.getData()
    },
    // 选择日期
    dateChange(date, dateString) {
      this.queryData.dateMin = dateString[0].toString();
      this.queryData.dateMax = dateString[1].toString();
      this.dateValue = date;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onShowSizeChange(current, pageSize) {
      this.queryData.size = pageSize;
      this.getData()
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    // 获取列表
    getData() {
      this.tableLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/offlineActivity/list', 'post', this.queryData).then(res => {
        this.tableLoading = false;
        let data = res.data.data;
        if (res.data.code == 200) {
          this.dataSource = data.data;
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.total = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    exportTask() {
      this.$message.loading('处理中')
      let params = this.queryData;
      params.remarks = ''
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/shop/exportBrand', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('导出成功！')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
      return result
    },
    reset() {
      this.queryData = {
        page: 0,
        size: 10,
      }
      this.dateValue = ''
      this.searchVal = []
      this.dateValueOrderTime = ""
      this.getData()
    },
    Printing(id, type,invoiceStatus) {
      if(invoiceStatus ==3){
        this.printVisit = true;
        this.printType = type;
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/offlineActivity/print/' + id, 'post').then(res => {
          this.printData = res.data.data;
          if(!res.data.data.executionBillVo){
            this.printData.executionBillVo = {
              type:null,
              typeName:null,
              invoiceAmount:null,
              businessExpenses:null,
              amount:null,
              plfPrice:null,
              profit:null,
              offilneActivityDetailsVoList:[],
              materielList:null,
              ordersKaList:null,
              stafflList:[],
            }
          }
          if(this.printData.expectReturnTime){
            this.printData.expectReturnTime = this.formatDate(res.data.data.expectReturnTime).slice(0, 10)
          }
          if (this.printData.length > 1) {
            var order = [3, 4, 1];
            this.printData.sort((a, b) => {
              const typeA = a.type;
              const typeB = b.type;
              const indexA = order.indexOf(typeA);
              const indexB = order.indexOf(typeB);
              return indexA - indexB;
            });
          }
        })
      }else{
        this.$message.warning('请先将发票全部开完后，才可以打印销售单!')
      }
      
    },
    details(row) {
      this.$router.push({
        path:'sfa_activity_details',
        query:{
          id:row.id
        }
      })
    }
  }
}
</script>
<style>
.print-text .ant-descriptions-item-label,.print-text .ant-descriptions-item-content{
  font-weight: 500;
  color: #000;
  font-size: 16px;
}
</style>
<style lang="less" scoped>
.print-box{
  border:1px solid #ddd;
  padding-top:20px;
  margin-bottom: 20px;
  
}
.table-box {
  font-size: 16 !important;
}
.table-text{
  font-size: 16px !important;
}
.print-text{
  margin: 20px 0 20px 20px;
}

.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

.spin-content {
  color: #5542F6;
  margin-top: 10px;
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}


.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}
</style>
<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}



@media (max-width: 1800px) {
  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  ::v-deep .ant-col-md-4 {
    // width: 16% !important;
    // margin-right: 20px;
  }
}


@media (max-width: 1600px) {
  ::v-deep .ant-col-md-4 {
    width: 18% !important;
  }
}

p {
  margin: 0;
}

.print-main ::v-deep .ant-card-body {
  // padding: 12px !important;
}

.print-main ::v-deep .ant-card-head {
  border: 0 !important;
  min-height: 0 !important;
  // padding: 0 12px !important;
}

.print-main ::v-deep .ant-card-head-title {
  padding: 0 !important;
  font-size: 18px;
}

.print-btn {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
 ::v-deep .ant-table-column-title {
    font-size: 16px !important;
 }
 ::v-deep .ant-table-row-cell-break-word {
  font-size: 14px !important;
 }
 ::v-deep .ant-table-thead{
  font-size: 16px !important;
 }
 .print-titles {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  margin: 20px 0;
}
.print-title {
  text-align: center;
  font-size: 26px;
  margin: 0 0 40px 0;

}

.print-table {
  margin-bottom: 30px;
}

.print-top {
  display: flex;
  // justify-content: center;
  color: #000;
  font-weight: 500;
  flex-wrap: wrap;
  padding: 0 12px;
  justify-content: left;
  box-sizing: border-box;
}
.underlin{
  text-indent: 90px;
  text-decoration: line-through;
}
.print-top li {
  width: 33.33%;
  text-align:left;
  margin-bottom: 16px;
  font-size: 16px;
  padding-right: 10px;
  box-sizing: border-box;
}

.print-top span {
  font-weight: 500;
  color: #000;
  font-size: 18px;
}

.print-top2 {

}

.print-top2 li {
  width: 24%;
}

.print-main {
  width: 100%;
  margin: auto;
  height: 100%;
}

.print-btm {
  display: flex;
  padding: 10px;
  font-size: 16px;
  margin-top: 40px;
  height: 100px;
  font-weight: 500;
  color: #000;
  box-sizing: border-box;
}

.print-btm p {
  flex: 1;
  color: #000;
}

.print-table ::v-deep .ant-card {
  font-size: 14px;
  color: #000 !important;
  font-weight: 500;
}

.print-table ::v-deep .ant-table {
  font-size: 14px;
  color: #000 !important;
}

.print-main ::v-deep .ant-card-head {
  border: 0 !important;
  min-height: 0 !important;
  padding: 0 12px !important;
  box-sizing: border-box;
}

/* 自定义表格线颜色 */
.print-main ::v-deep .ant-table-tbody>tr>td {
  border-color: #333;
  /* 设置表格单元格的边框颜色 */
}

/* 可选：设置表格头部线颜色 */
.print-main ::v-deep .ant-table-thead>tr>th {
  border-color: #333;
  /* 设置表格头部单元格的边框颜色 */
}

/* 可选：设置表格外边框颜色 */
.print-main ::v-deep .ant-table {
  border-color: #000;
  /* 设置表格的外边框颜色 */
}

.print-main ::v-deep .ant-table-bordered .ant-table-body>table {
  border-color: #333;
  /* 设置表格的外边框颜色 */
}

.print-main table {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-spacing: 0;

}

.print-main table {
  width: 100%;
}

.print-main tr {
  width: 150px;
  height: 60px;
  border-bottom: 1px solid #000;
  padding: 8px 10px;
  box-sizing: border-box;
  border-right: 1px solid #000;
  line-height: 20px;
  text-align: center;
}

.print-main td {
  width: 150px;
  height: 60px;
  border-bottom: 1px solid #000;
  padding: 8px 10px;
  box-sizing: border-box;
  border-right: 1px solid #000;
  line-height: 20px;
  text-align: center;
}
</style>
<!-- 打印样式表 -->
<style media="print">
/* 设置打印时的样式 */
@media print {

  /* 隐藏不需要打印的元素 */
  .no-print {
    display: none;
  }

  .print-main {
    zoom: 65%;
    page-break-after: always;
    padding: 30px 0;
    box-sizing: border-box;
  }
  .print-titles{
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }
  .ant-table {
    /* page-break-inside: avoid; */
  }

  /* 隐藏日期和网址 */
  @page {
    margin-top: 8mm;
  }

}
</style>